<template>
  <!-- display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
   -->
  <div style="width:100%; display:flex; position:fixed; top:70px; background-color:#ffffff; z-index:1;">
    <div style="width:100%; padding:20px 20px 0; box-sizing:border-box;">
      <ul class="intelligent-menu">
         <li><a href="#">運用</a>
            <ul>
               <li><a href="#" @click="doRouterMove('/intelligent-home')">ホーム</a></li>
               <!-- <li><a href="#" @click="doRouterMove('/intelligent-ocr-result-capture')">OCR処理済ファイル取込</a></li>
               <li><a href="#">分析（厚労省ツール）</a></li>
               <li><a href="#">納品書 & 請求書作成</a></li>
               <li><a href="#">分析結果印刷</a></li> -->
            </ul>
         </li>
         <li><a href="#">システム</a>
            <ul>
               <li><a href="#">アンケート問題設定</a></li>
               <li><a href="#">分析コメント設定</a></li>
               <li><a href="#">企業情報</a></li>
               <li><a href="#">職場情報</a></li>
               <li><a href="#">社員情報</a></li>
            </ul>
         </li>
      </ul>
    </div>
  </div>

  <div style="padding:170px 50px 0;">
    <h3 style="text-align:left; color:#696969;">{{ $store.state.common.sScreenTitle }}</h3>
    <hr/>
  </div>

  <router-view/>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    created() {

    },
    mounted() {

    },
    methods: {
      doRouterMove(path) {
        console.log('path = ', path);
        this.$router.push(path);
      }
    }
  }

</script>
